import React from 'react';
import { isAuthenticated, login, getIdToken } from "../utils/auth"
import Layout from '../components/Layout';
import uuid from 'react-uuid'
import { format } from 'date-fns'

const TodoPage = () => {

    const [todoTitle, setTodoTitle] = React.useState("");
    const [todoDescription, setTodoDescription] = React.useState("");

    const [editTodoId, setEditTodoId] = React.useState("");
    const [editTodoTitle, setEditTodoTitle] = React.useState("");
    const [editTodoDescription, setEditTodoDescription] = React.useState("");
    const [editTodoDone, setEditTodoDone] = React.useState(false);
    const [showEditForm, setShowEditForm] = React.useState(false);

    const [tableData, setTableData] = React.useState("");
    let tableDataTemp = [];

    const handleTodoTitleChange = (event) => {
        const value = event.target.value

        setTodoTitle(value)
    }

    const handleTodoDescriptionChange = (event) => {
        const value = event.target.value

        setTodoDescription(value)
    }

    const handleEditTodoTitleChange = (event) => {
        const value = event.target.value

        setEditTodoTitle(value)
    }

    const handleEditTodoDescriptionChange = (event) => {
        const value = event.target.value

        setEditTodoDescription(value)
    }

    const handleEditTodoDoneChange = (event) => {
        const value = event.target.checked

        setEditTodoDone(value)
    }

    const handleShowEditFormChange = (id, title, description, done) => {
        setEditTodoId(id);
        setEditTodoTitle(title)
        setEditTodoDescription(description);
        setEditTodoDone(done)
        setShowEditForm(true);
    }

    const handleAddTodoItem = async () => {

        const token = getIdToken();

        const requestHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        });

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({ todo: todoTitle, description: todoDescription }),
        };

        try {
            const response = await fetch(process.env.GATSBY_TODO_URL, requestOptions);

            console.log(response);

            if (response.status === 200) {
                setTodoTitle('');
                setTodoDescription('');
                await fetchMessages();
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleDeleteTodoItem = async (id) => {

        const token = getIdToken();

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': 'Bearer ' + token }
        };

        let url = new URL(process.env.GATSBY_TODO_URL);
        url.searchParams.append("id", id);

        try {
            const response = await fetch(url, requestOptions)
            if (response.status === 200) {
                await fetchMessages()
            }
        } catch (err) {
            console.log(err);
        }
    }

    const addDataToTableSource = (data) => {
        let tableRow = {
            id: data._id,
            todo: data.todo,
            description: data.description,
            created: format(new Date(data.created), 'yyyy-MM-dd HH:mm:ss'),
            updated: format(new Date(data.updated), 'yyyy-MM-dd HH:mm:ss'),
            done: data.done
        }

        tableDataTemp.push(tableRow);
    }

    const fetchMessages = async () => {

        const token = getIdToken();

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + token }
        };

        let response = await fetch(process.env.GATSBY_TODO_URL, requestOptions);
        let responseJson = await response.json();

        responseJson.forEach(data => {
            addDataToTableSource(data);
        })

        setTableData(tableDataTemp);
    }

    const editTodoItem = async () => {

        const token = getIdToken();

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Authorization': 'Bearer ' + token }
        };

        let url = new URL(process.env.GATSBY_TODO_URL);
        url.searchParams.append("id", editTodoId);
        url.searchParams.append("todo", editTodoTitle);
        url.searchParams.append("done", editTodoDone);
        url.searchParams.append("description", editTodoDescription);

        try {
            const response = await fetch(url, requestOptions);
            if (response.status === 200) {
                setEditTodoId("");
                setEditTodoTitle("")
                setEditTodoDescription("");
                setEditTodoDone(false)
                setShowEditForm(false)
                await fetchMessages();
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const EditTodoItem = () => {
        return (
            <div className="row" key={uuid()} id="edit">
                <div className="col-12">
                    <h4>Edit Todo</h4>
                </div>
                <div className="col-4 col-12-small">
                    <ul className="alt">
                        <li><label htmlFor="todo-title">Todo Title</label></li>
                        <li><input type="text" name="todo-title" id="todo-title" value={editTodoTitle} onChange={(event) => handleEditTodoTitleChange(event)} /></li>
                    </ul>
                </div>
                <div className="col-4 col-12-small">
                    <ul className="alt">
                        <li><label htmlFor="todo-description">Description</label></li>
                        <li><textarea name="todo-description" id="todo-description" rows="3" value={editTodoDescription} onChange={(event) => handleEditTodoDescriptionChange(event)}></textarea></li>
                    </ul>
                </div>
                <div className="col-4 col-12-small">
                    <ul className="alt actions fit small">
                        <li><input type="checkbox" id="todo-done" name="todo-done" checked={editTodoDone} onChange={(event) => handleEditTodoDoneChange(event)} />
                            <label htmlFor="todo-done">Done</label></li>
                        <li><a href="#read" className="button primary fit small" onClick={() => editTodoItem()}>Edit Item</a></li>
                    </ul>
                </div>
            </div>
        )
    }

    const TodoListTable = () => {
        return ((tableData !== undefined) && (tableData.length !== undefined) && tableData.length > 0 && (
            tableData.map((row) => (
                <div className="row" key={uuid()}>
                    <div className="col-12">
                        <h4>{row.todo}</h4>
                    </div>
                    <div className="col-4 col-12-small">
                        <ul className="alt">
                            <li>{row.description}</li>
                            <li>Done:  {row.done === true ? "True" : "False"}</li>
                        </ul>
                    </div>
                    <div className="col-4 col-12-small">
                        <ul className="alt">
                            <li>Created:  {row.created}</li>
                            <li>Updated:  {row.updated}</li>
                        </ul>
                    </div>
                    <div className="col-4 col-12-small">
                        <ul className="alt actions fit small">
                            <li><a href="#edit" className="button primary fit small" onClick={() => handleShowEditFormChange(row.id, row.todo, row.description, row.done)}>Edit</a></li>
                            <li><a href="#read" className="button fit small" onClick={() => handleDeleteTodoItem(row.id)}>Delete</a></li>
                        </ul>
                    </div>
                </div>
            ))
        ))
    }

    if (!isAuthenticated()) {
        login()
        return <p>Redirecting to login...</p>
    }

    return (
        <Layout fullMenu>
            <section id="banner">
                <header>
                    <div className="inner">
                        <h2>Todo List</h2>
                    </div>
                </header>
                <div className="wrapper" id="read">
                    <div className="inner">
                        <h3>Get Todo List</h3><br /><br />
                        <div className="col-12">
                            <ul className="actions">
                                <li><a href="#read" className="button primary" onClick={() => fetchMessages()}>Get List</a></li>
                            </ul>
                        </div>
                        {showEditForm && <EditTodoItem />}
                        <TodoListTable />
                    </div>
                </div>
                <div className="wrapper" id="add">
                    <div className="inner">
                        <h3>Add Todo</h3><br /><br />
                        <div className="row gtr-uniform">
                            <div className="col-6 col-12-xsmall">
                                <label htmlFor="todo-title">Todo Title</label>
                                <input type="text" name="todo-title" id="todo-title" value={todoTitle} onChange={(event) => handleTodoTitleChange(event)} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="todo-description">Description</label>
                                <textarea name="todo-description" id="todo-description" rows="3" value={todoDescription} onChange={(event) => handleTodoDescriptionChange(event)}></textarea>
                            </div>
                            <div className="col-12">
                                <ul className="actions">
                                    <li><a href="#add" className="button primary" onClick={() => handleAddTodoItem()}>Add Todo</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout >
    )
};

export default TodoPage;